import React, { useEffect, useState } from 'react';

import {
  IonAlert,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import 'animate.css';
import { chevronForward } from 'ionicons/icons';

import Documentation from '../../assets/documentation.png';
import MedicalRecord from '../../assets/medical-history.png';
import Reintegro from '../../assets/refund.png';
import { useMedia } from '../../context/MediaContext';
import { useSiniestro } from '../../context/SiniestroContext';
import { PhotoItem } from '../../interfaces/PhotoItem';
import { SubVetForm } from '../../interfaces/SubVetForm';
import AddVetContact from '../AddVetContact';
import ToastAlert from '../ToastAlert';
import './SiniestroMenu.scss';
import { SiniestroMenuProps } from './types';

const SiniestroMenu: React.FC<SiniestroMenuProps> = ({ setStep, nombre }) => {
  const {
    siniestro: { data: siniestro },
    siniestroId,
    documentacion: { data: documentacion },
    indicaciones: { data: indicaciones },
  } = useSiniestro();

  const { media } = useMedia();
  const [missingDocumentsCount, setMissingDocumentsCount] = useState<number>(0);

  const [indicacionesSeleccionadas, setIndicacionesSeleccionadas] = useState<
    SubVetForm[]
  >([]);

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isOkAlertOpen, setOkAlertOpen] = useState<boolean>(false);
  const [isFailAlertOpen, setFailAlertOpen] = useState<boolean>(false);

  const pendientes = indicaciones
    ? indicaciones.filter((i) => i.estado === 'PENDIENTE').length
    : 0;

  useEffect(() => {
    const tags: { [key: string]: PhotoItem | null } = {
      dni_frente: null,
      dni_dorso: null,
      libreta_vacunacion: null,
      cbu: null,
      tercero_cbu: null,
      constancia: null,
      // doc_extra: null,
    };

    // Actualizar tags con los elementos encontrados en media
    media.forEach((element) => {
      if (tags.hasOwnProperty(element.tag.detail)) {
        // Verificar la concordancia con siniestroId si está definido
        if (
          !siniestroId ||
          element.tag.type.includes(`siniestro: ${siniestroId}`)
        ) {
          tags[element.tag.detail] = element;
        }
      }
    });

    // Contar los campos faltantes
    let missingCount = 0;

    if (tags.cbu || (tags.tercero_cbu && tags.constancia)) {
      // Si existe cbu o (tercero_cbu y constancia), ignorar la falta de cbu y tercero_cbu y constancia en el contador
      missingCount += !tags.dni_frente ? 1 : 0;
      missingCount += !tags.dni_dorso ? 1 : 0;
      missingCount += !tags.libreta_vacunacion ? 1 : 0;
    } else if (tags.tercero_cbu || tags.constancia) {
      // Si existe tercero_cbu o constancia, ignorar la falta de cbu en el contador
      missingCount += !tags.dni_frente ? 1 : 0;
      missingCount += !tags.dni_dorso ? 1 : 0;
      missingCount += !tags.libreta_vacunacion ? 1 : 0;
      missingCount += !tags.cbu ? 1 : 0;
    } else {
      // Contar todos los documentos faltantes si no se cumple ninguna condición
      missingCount += !tags.dni_frente ? 1 : 0;
      missingCount += !tags.dni_dorso ? 1 : 0;
      missingCount += !tags.libreta_vacunacion ? 1 : 0;
      missingCount += !tags.cbu ? 1 : 0;
      missingCount += !tags.tercero_cbu ? 1 : 0;
      missingCount += !tags.constancia ? 1 : 0;
    }

    setMissingDocumentsCount(missingCount);
  }, [media, siniestroId]);

  return !siniestro ? (
    <IonSpinner color="dark" />
  ) : (
    <div className="siniestro-menu ion-margin-top">
      <div className="siniestro-menu__heading ion-padding-horizontal">
        <p>
          Para gestionar su reintegro complete los datos del veterinario que
          atendio a {nombre} en la sección
          <span className="bold"> Nuevo reintegro</span>, adjunte comprobante de
          gastos en <span className="bold"> Reintegros</span> y cumplimente la
          sección <span className="bold">Documentación</span>.
        </p>
      </div>

      <div className="ion-padding ion-margin-top">
        <IonGrid
          className="ion-no-padding ion-margin-bottom"
          onClick={(e: any) => {
            e.persist();
            setShowAlert(true);
          }}
        >
          <IonRow>
            <IonCol size="3" className="siniestro-menu__img-box">
              <img src={Reintegro} />
            </IonCol>
            <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
              <div className="siniestro-menu__title-box">Nuevo reintegro</div>
            </IonCol>
            <IonCol>
              <IonIcon icon={chevronForward} color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid
          className="ion-no-padding ion-margin-bottom"
          onClick={() => setStep(1)}
        >
          <IonRow>
            <IonCol size="3" className="siniestro-menu__img-box">
              {pendientes >= 1 && (
                <div className="siniestro-menu__badge animate__animated animate__bounce">
                  {pendientes}
                </div>
              )}
              <img src={MedicalRecord} />
            </IonCol>
            <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
              <div className="siniestro-menu__title-box">Reintegros</div>
            </IonCol>
            <IonCol>
              <IonIcon icon={chevronForward} color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid
          className="ion-no-padding ion-margin-bottom"
          onClick={() => setStep(2)}
        >
          <IonRow>
            <IonCol size="3" className="siniestro-menu__img-box">
              {missingDocumentsCount !== 0 && (
                <div className="siniestro-menu__badge animate__animated animate__bounce">
                  {missingDocumentsCount}
                </div>
              )}

              <img src={Documentation} />
            </IonCol>
            <IonCol offsetSm="0.5" sizeXs="7.5" sizeSm="8">
              <div className="siniestro-menu__title-box">Documentación</div>
            </IonCol>
            <IonCol>
              <IonIcon icon={chevronForward} color="primary" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      <IonAlert
        mode="ios"
        isOpen={showAlert}
        onWillDismiss={() =>
          setShowPopover({ showPopover: true, event: undefined })
        }
        onDidDismiss={() => setShowAlert(false)}
        header="Aviso importante"
        // subHeader="Mensaje importante"
        message="Para que el trámite continúe su curso, es necesario que el veterinario reciba y complete el formulario que le será enviado, de lo contrario el reintegro no será procesado."
        buttons={['Entendido']}
      />

      <AddVetContact
        indicacion={undefined}
        indicacionesSeleccionadas={indicacionesSeleccionadas}
        setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        setOkAlertOpen={setOkAlertOpen}
        setFailAlertOpen={setFailAlertOpen}
        nombre={nombre}
      />

      <ToastAlert
        isOpen={isOkAlertOpen}
        setShowToast={setOkAlertOpen}
        message="Formulario enviado correctamente!"
        color="success"
        duration={3500}
      />

      <ToastAlert
        isOpen={isFailAlertOpen}
        setShowToast={setFailAlertOpen}
        message="El formulario no se pudo enviar. Por favor intentelo nuevamente más tarde."
        color="danger"
        duration={3500}
      />
    </div>
  );
};

export default SiniestroMenu;
