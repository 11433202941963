import React, { useRef, useState } from 'react';

import { IonCol, IonIcon, IonItem, IonRow } from '@ionic/react';
import { camera } from 'ionicons/icons';

import PhotoInGallery from '../../assets/photo-in-gallery.png';
import { useMedia } from '../../context/MediaContext';
import { formatMediaDetailName } from '../../utils/General';
import FileOptionsPopover from '../FileOptionsPopover';
import './FileUploader.scss';
import { FileUploaderProps } from './types';

const FileUploader: React.FC<FileUploaderProps> = ({
  tag,
  placeholder,
  disabled,
  hasData,
}) => {
  const {
    handlePDF,
    setShowMediaState,
    setFileToBeViewed,
    setShowFileSizeValidationToast,
  } = useMedia();

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    if (file) {
      handlePDF(event, tag, setShowFileSizeValidationToast);
      // Reset the input value to allow the same file to be uploaded again
      event.target.value = null;
    }
  };

  const openPopover = () => {
    setShowPopover((prevState) => ({
      ...prevState,
      showPopover: true,
      event: undefined,
    }));
  };

  return (
    <div className={disabled ? 'file-uploader disabled' : 'file-uploader'}>
      <IonItem className="ion-no-padding">
        <IonRow className="file-uploader__container">
          <div
            onClick={() => {
              if (hasData) {
                setFileToBeViewed(tag.detail);
                setShowMediaState('viewFile');
              } else {
                openPopover();
              }
            }}
          >
            {hasData ? (
              <div className="file-uploader__withMedia">
                <img src={PhotoInGallery} />
                <div>
                  {tag.type.includes('docPersonal')
                    ? formatMediaDetailName(tag.detail)
                    : formatMediaDetailName(tag.type)}
                </div>
              </div>
            ) : (
              placeholder
            )}
          </div>
          <IonCol
            onClick={() => openPopover()}
            className="file-uploader__iconBox"
          >
            <IonIcon icon={camera} color="light" />
          </IonCol>
        </IonRow>
      </IonItem>

      <input
        hidden
        type="file"
        ref={fileInputRef}
        onChange={handleFileInput}
        accept=".pdf, application/pdf"
      />

      <FileOptionsPopover
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        tag={tag}
        handleFileInput={() => fileInputRef.current?.click()}
      />
    </div>
  );
};

export default FileUploader;
