import { useRef, useState } from 'react';

import {
  addCbu,
  addDni,
  addLibretaVacunacion,
  addExtra,
} from '../axios/addDocumentacion';

interface InternalValues {
  file: Blob | null;
}

export const useFile = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const values = useRef<InternalValues>({
    file: null,
  });

  function urlToBlob(url: string): Promise<Blob> {
    return fetch(url)
      .then((response) => response.blob())
      .catch((error) => {
        throw new Error(`Failed to fetch blob from URL: ${error}`);
      });
  }

  function dataURLtoBlob(dataUrl: string): Blob {
    if (!dataUrl.startsWith('data:') || !dataUrl.includes(',')) {
      throw new Error('Invalid data URL');
    }

    const arr = dataUrl.split(',');
    if (arr.length !== 2) {
      throw new Error('Invalid data URL format');
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      throw new Error('Invalid MIME type');
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);

    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    return new Blob([u8arr], { type: mime });
  }

  const convertToBlob = async (input: string): Promise<Blob> => {
    if (input.startsWith('data:')) {
      return dataURLtoBlob(input);
    } else if (input.startsWith('blob:')) {
      return urlToBlob(input);
    } else {
      throw new Error('Unsupported URL type');
    }
  };

  const onFileChange = async (
    siniestroId: string,
    type: string,
    cameraSource: boolean,
    photo?: string
  ) => {
    if (!photo) {
      console.error('Photo is not defined');
      return;
    }

    try {
      values.current.file = await convertToBlob(photo);
    } catch (error) {
      console.error('Failed to convert photo to blob:', error);
      return;
    }

    function typeHandler(): string {
      switch (type) {
        case 'dni_frente':
        case 'dni_dorso':
          return 'dni';
        case 'cbu':
        case 'tercero_cbu':
          return 'file';
        case 'libreta_vacunacion':
          return 'libreta_vacunacion';
        case 'constancia':
          return 'doc_extra';
        default:
          return 'file';
      }
    }

    let formData = new FormData();
    formData.append('siniestroId', siniestroId);
    formData.append('label', type);
    formData.append(typeHandler(), values.current.file);



    function handler() {
      switch (type) {
        case 'dni_frente':
          formData.append('lado', 'frente');
          return addDni(formData);
        case 'dni_dorso':
          formData.append('lado', 'dorso');
          return addDni(formData);
        case 'cbu':
          formData.append('tipo', 'cbu');
          return addCbu(formData);
        case 'tercero_cbu':
          formData.append('tipo', 'tercero_cbu');
          return addCbu(formData);
        case 'libreta_vacunacion':
          return addLibretaVacunacion(formData);
        case 'constancia':
          formData.append('tipo', 'constancia');
          return addExtra(formData);
        // case 'doc_extra':
        //   return addExtra(formData);
        default:
          throw new Error(`Unhandled type: ${type}`);
      }
    }

    try {
      setIsLoading(true);
      const res = await handler();
      // Manejar la respuesta si es necesario
      // setIsLoading(false);
      // setIsUpdating(true);
    } catch (error) {
      console.error('Failed to handle file change:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return { onFileChange, isUpdating, isLoading };
};
