import React, { useState } from 'react';

import { IonCol, IonGrid, IonRow } from '@ionic/react';

import MissingData from '../../assets/missing-data.png';
import { useSiniestro } from '../../context/SiniestroContext';
import { SubVetForm } from '../../interfaces/SubVetForm';
import Gallery from '../Gallery';
import Indicaciones from '../Indicaciones';
import ToastAlert from '../ToastAlert';
import './ConsultasIndicaciones.scss';
import { ConsultasIndicacionesProps } from './types';

const ConsultasIndicaciones: React.FC<ConsultasIndicacionesProps> = ({
  pet,
}) => {
  const {
    indicaciones: { data: indicaciones },
  } = useSiniestro();
  const [indicacionesSeleccionadas, setIndicacionesSeleccionadas] = useState<
    SubVetForm[]
  >([]);

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isOkAlertOpen, setOkAlertOpen] = useState<boolean>(false);
  const [isFailAlertOpen, setFailAlertOpen] = useState<boolean>(false);

  const { nombre } = pet || {};

  return (
    <div className="consultas-indicaciones">
      {indicaciones?.length === 0 ? (
        <IonGrid>
          <IonRow>
            <IonCol>
              <img
                src={MissingData}
                alt="misssingData_pic"
                className="misssingData_pic"
              />
              <div className='missingData_title'>Ups... No hay reintegros solicitados para mostrar.</div>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        <Indicaciones
          indicacionesSeleccionadas={indicacionesSeleccionadas}
          setIndicacionesSeleccionadas={setIndicacionesSeleccionadas}
          setOkAlertOpen={setOkAlertOpen}
          setFailAlertOpen={setFailAlertOpen}
          nombre={nombre}
          setShowPopover={setShowPopover}
        />
      )}

      {/* //TODO UNICA INSTANCIA */}
      <Gallery />

      <ToastAlert
        isOpen={isOkAlertOpen}
        setShowToast={setOkAlertOpen}
        message="Formulario enviado correctamente!"
        color="success"
        duration={3500}
      />

      <ToastAlert
        isOpen={isFailAlertOpen}
        setShowToast={setFailAlertOpen}
        message="El formulario no se pudo enviar. Por favor intentelo nuevamente más tarde."
        color="danger"
        duration={3500}
      />
    </div>
  );
};

export default ConsultasIndicaciones;
