import React, { useRef, useState } from 'react';

import { IonPopover, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import html2canvas from 'html2canvas';
import { cloudDownload } from 'ionicons/icons';
import moment from 'moment';

import Logo from '../../assets/FielPetSinLogo.png';
import Check from '../../assets/check.png';
import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import { capitalizeFirstLetter } from '../../utils/General';
import './CarnetAsociado.scss';
import { CarnetAsociadoProps } from './types';

const CarnetAsociado: React.FC<CarnetAsociadoProps> = ({
  pet,
  plan,
  carnetAsociadoPopoverState,
  setCarnetAsociadoPopoverState,
}) => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const { animal, raza, img_link, nombre, fecha_nacimiento } = pet || {};
  const { plan: petPlan, poliza: poliza, date } = plan || {};
  const elementRef = useRef<any>(null);

  const placeholder = animal === 'Perro' ? DogPlaceholder : CatPlaceholder;
  const vigencia = () => {
    const fecha = moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
    const formatoFecha = fecha.format('MM/YY');
    const fechaHasta = fecha.add(1, 'year').format('MM/YY');

    return {
      desde: formatoFecha,
      hasta: fechaHasta,
    };
  };

  const vigenciaOk = vigencia();

  const handleDownload = async () => {
    try {
      const element = elementRef.current;
      if (!element) {
        throw new Error('Element reference is null or undefined');
      }

      setDownloading(true);

      // Retraso para asegurar que el estado se actualice
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 300));

      // Renderizar el elemento a un canvas
      const canvas = await html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        scale: 2, // Aumenta la resolución del canvas (mayor calidad)
      });

      // Definir el tamaño deseado (ejemplo de carnet de conducir)
      const desiredWidth = 480; // Ancho en píxeles
      const desiredHeight = 300; // Alto en píxeles

      // Crear un nuevo canvas con el tamaño deseado
      const resizedCanvas = document.createElement('canvas');
      resizedCanvas.width = desiredWidth;
      resizedCanvas.height = desiredHeight;

      // Obtener el contexto 2D del nuevo canvas
      const ctx = resizedCanvas.getContext('2d');

      if (ctx) {
        // Dibujar el contenido del canvas original en el nuevo canvas redimensionado
        ctx.drawImage(canvas, 0, 0, desiredWidth, desiredHeight);

        // Convertir el nuevo canvas a una URL de imagen
        const data = resizedCanvas.toDataURL('image/png');

        // Crear un enlace para descargar la imagen
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
          link.href = data;
          link.download = `carnet${nombre}_${moment(new Date()).format(
            'DD/MM/YYYY'
          )}.png`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // Si no se puede usar el atributo download, abrir la imagen en una nueva ventana
          window.open(data);
        }
      } else {
        throw new Error('Failed to get 2D context');
      }
    } catch (error) {
      console.error('Error al descargar la imagen:', error);
      // Mensaje de error al usuario si es necesario
    } finally {
      setDownloading(false);
    }
  };

  return (
    <IonPopover
      mode="ios"
      cssClass="carnet-asociado-popover"
      event={carnetAsociadoPopoverState.event}
      isOpen={carnetAsociadoPopoverState.showPopover}
      onDidDismiss={() =>
        setCarnetAsociadoPopoverState({ showPopover: false, event: undefined })
      }
    >
      <IonGrid ref={elementRef} className="ion-padding-horizontal">
        <IonRow>
          <IonCol>
            <div className="top-box">
              <img src={Logo} />
              <p>Carnet de asegurado</p>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5" className="carnetAsociado_profilePic">
            {img_link ? (
              <img
                src={`data:image/jpeg;base64,${img_link}`}
                alt="petProfilePic"
              />
            ) : (
              <img src={placeholder} alt={nombre} />
            )}
          </IonCol>
          <IonCol>
            <div className="pet_topInfo-box">
              <div className="data">
                <div className="title">Nombre</div>
                <div className="info">{nombre}</div>
              </div>

              <div className="data">
                <div className="title">Raza</div>
                <div className="info">{raza}</div>
              </div>
            </div>

            <div className="data">
              <div className="title">Fecha de nacimiento</div>
              <div className="info">
                {moment(fecha_nacimiento).format('DD/MM/YYYY')}
              </div>
            </div>

            <div className="data">
              <div className="title">Plan</div>
              <div className="info">
                {capitalizeFirstLetter(petPlan?.toLowerCase())}
              </div>
            </div>

            <div className="data">
              <div className="title">Fecha de vigencia</div>
              <div className="info">
                {vigenciaOk.desde} - {vigenciaOk.hasta}
              </div>
            </div>

            <div className="data">
              <div className="title">Poliza</div>
              <div className="info">{poliza}</div>
            </div>
          </IonCol>
        </IonRow>

        {!downloading ? (
          <div onClick={handleDownload} className="downloadIcon">
            <IonIcon icon={cloudDownload} color={'dark'} />
          </div>
        ) : (
          <img className="downloadedImg" src={Check} />
        )}

        <div className="emision">
          Emisión: {moment(new Date()).format('DD/MM/YYYY')}
        </div>
      </IonGrid>
    </IonPopover>
  );
};

export default CarnetAsociado;
